import React, {Component} from 'react';
import {HashRouter, NavLink, Redirect, Route, Switch} from 'react-router-dom';
import './App.css';
import Account from "./components/account/Account";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Main from "./components/main/Main";
import Help from "./components/help/Help";
import Certificates from "./components/certificates/Certificates";
import Catalog from "./components/catalog/Catalog";
import Course from "./components/course/Course";
import NotFound from "./components/common/NotFound";
import FatalError from "./components/common/FatalError";
import NotAllowed from "./components/common/NotAllowed";
import About from "./components/about/About";
import MainLogin from "./components/login/MainLogin";
import Promo from "./components/promo/Promo";
import Unsubscribe from "./components/unsubscribe/Unsubscribe";
import PreAds from "./components/ads/PreAds";
import PostAds from "./components/ads/PostAds";
import Products from './components/products/Products';

const APP_VERSION = 202472;

class App extends Component {
    _pinger = undefined;

    constructor(props) {
        super(props);
        this.state = {
            pushedPath: false,
            userData: undefined,
            logged: false,
            passwordAlert: false,
            employmentAlert: false,
            emailAlert: false
        }
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/public/auth/check`, {
            credentials: 'include'
        })
            .then(response => response.json())
            .then(data => {
                if (data['result']) {
                    this.setState({
                        logged: true,
                        userData: data
                    });
                }
            });
        this._pinger = setInterval(() =>
            fetch(`${process.env.REACT_APP_API_URL}/public/ping?version=${APP_VERSION}`, {
                credentials: 'include'
            })
                .then(response => response.json())
                .then(data => {
                    if (data && parseInt(data) > APP_VERSION) {
                        window.location.reload(true);
                    }
                }), 60000);
    }

    componentWillUnmount() {
        clearInterval(this._pinger);
        this._pinger = undefined;
    }

    selectPage(event) {
        event.preventDefault();
        this.setState({
            pushedPath: event.target.value
        });
    }

    logIn(userData) {
        this.setState({
            userData: userData,
            passwordAlert: userData.passwordAlert,
            employmentAlert: userData.employmentAlert,
            emailAlert: userData.emailAlert,
            logged: true
        });
    }

    logOut(destroySession) {
        if (destroySession) {
            this.setState({
                userData: undefined,
                logged: false
            });
        }
    }

    render() {
        return <HashRouter>
            {this.state.pushedPath ? <Redirect to={this.state.pushedPath} push={true}/> : ''}
            <div className="container">
                <Header key={this.state.userData}
                        userData={this.state.userData}
                        logoutCallback={event => this.logOut(event)}/>
                {!this.state.logged ?
                    <div className="row">
                        <div className="adv-bar navigation-bar col-sm-12 col-md-3 col-lg-2">
                            <select className="hidden-md hidden-lg" onChange={event => this.selectPage(event)}>
                                <option value="/">Logowanie</option>
                                <option value="/about">O nas</option>
                            </select>
                            <ul className="hidden-sm">
                                <li><NavLink to='/'
                                             isActive={(_, location) => {
                                                 return ['/', '/register', '/recovery'].includes(location.pathname);
                                             }}>Logowanie</NavLink></li>
                                <li><NavLink exact={true} to='/about'>O nas</NavLink></li>
                            </ul>
                            <PreAds/>
                        </div>
                        <Switch>
                            <Route exact path='/about' component={About}/>
                            <Route path='/promo/:promoCode' render={props => <MainLogin {...props}
                                                                                        promoAlert={true}
                                                                                        loginCallback={userData => this.logIn(userData)}/>}/>
                            <Route path='/unsubscribe/:code' component={Unsubscribe}/>
                            <Route path='/' render={props => <MainLogin {...props}
                                                                        loginCallback={userData => this.logIn(userData)}/>}/>

                        </Switch>
                    </div> :
                    <div className="row">
                        <div className="navigation-bar col-sm-12 col-md-3 col-lg-2">
                            <select className="hidden-md hidden-lg" onChange={event => this.selectPage(event)}>
                                <option value="/">Strona główna</option>
                                <option value="/about">O nas</option>
                                <option value="/certificates">Moje certyfikaty</option>
                                <option value="/catalog">Katalog kursów</option>
                                <option value="/products">Produkty naturalne</option>
                                <option value="/account">Moje konto</option>
                                <option value="/help">Pomoc</option>
                            </select>
                            <ul className="hidden-sm">
                                <li><NavLink exact={true} to='/'>Strona główna</NavLink></li>
                                <li><NavLink exact={true} to='/about'>O nas</NavLink></li>
                                <li><NavLink exact={true} to='/certificates'>Moje certyfikaty</NavLink></li>
                                <li><NavLink to='/catalog'>Katalog kursów</NavLink></li>
                                <li><NavLink exact={true} to='/products'>Produkty naturalne</NavLink></li>
                                <li><NavLink exact={true} to='/account'>Moje konto</NavLink></li>
                                <li><NavLink exact={true} to='/help'>Pomoc</NavLink></li>
                            </ul>
                            <PostAds/>
                        </div>
                        <Switch>
                            <Route exact path='/' render={props => <Main {...props}
                                                                         userData={this.state.userData}
                                                                         removePasswordAlertCallback={() => this.setState({
                                                                             passwordAlert: false
                                                                         })}
                                                                         removeEmploymentAlertCallback={() => this.setState({
                                                                             employmentAlert: false
                                                                         })}
                                                                         employmentAlert={this.state.employmentAlert}
                                                                         passwordAlert={this.state.passwordAlert}
                                                                         emailAlert={this.state.emailAlert}/>}/>
                            <Route exact path='/about' component={About}/>
                            <Route exact path='/products' component={Products}/>
                            <Route exact path='/account' component={Account}/>
                            <Route path='/catalog' component={Catalog}/>
                            <Route exact path='/certificates' component={Certificates}/>
                            <Route exact path='/help' component={Help}/>
                            <Route path='/course/:courseId' component={Course}/>
                            <Route path='/promo/:promoCode' component={Promo}/>
                            <Route path='/unsubscribe/:code' component={Unsubscribe}/>
                            <Route path='/application-error' component={FatalError}/>
                            <Route path='/not-allowed' component={NotAllowed}/>
                            <Route path='/' component={NotFound}/>
                        </Switch>
                    </div>}
                <Footer version={APP_VERSION}/>
            </div>
        </HashRouter>;
    }
}

export default App;
